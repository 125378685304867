<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de transferencias de documentos</h1>
                        <h5 class="subtitle">Tipo de transferencia: {{ tpa_nmbre }}</h5>
                        <h5 class="subtitle">Sala: {{ sal_nmbre }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteInventarioDocs" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                      <th class="">Estante</th>
                                      <th class="">Caja</th>
                                      <th>Inventario</th>
                                      <th>Documento</th>
                                      <th>Cód. Dependencia</th>
                                      <th>Dependencia</th>
                                      <th>Cód. Serie</th>
                                      <th>Serie</th>
                                      <th>Retención (años)</th>
                                      <th>Fecha inicio</th>
                                      <th>Fecha fin</th>
                                      <th>Nombre del expediente</th>
                                      <th>Tomo</th>
                                      <th>Archivos adjuntos</th>
                                      <th># Folios</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ item.est_cdgo }}</td>
                                    <td>{{ item.caj_cdgo }}</td>
                                    <td>{{ item.lgj_nmro }}</td>
                                    <td>{{ item.documento }}</td>
                                    <td>{{ item.dep_cdgo }}</td>
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.ser_cdgo }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td>{{ item.retencion }}</td>
                                    <td>{{ item.dcm_fchaini | formatDateUtc }}</td>
                                    <td>{{ item.dcm_fchafin | formatDateUtc }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dcm_tomo }}</td>
                                    <td>{{ item.dcm_adjuntos }}</td>
                                    <td>{{ item.dcm_nflios }}</td>
                                  </tr>
                              </tbody>
                              <tr style="font-weight:bold">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>TOTAL</td>
                                  <td>{{ nflios.toLocaleString() }}</td>
                              </tr>                              
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDateUtc } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      dependencias: [],
      series: [],
      estantes: [],
      debug: null,
      fecha: null,
      tpa_nmbre: '',
      sal_nmbre: '',
      showProgress: false,
      nflios: 0
    }
  },
  filters: {
    formatDateUtc
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchTpocor();
    this.fetchEstante();
    this.fetchDependencia();
    this.fetchSerie();
    this.debug = this.$route.params;
    this.tpa_nmbre = this.$route.params.tpa_nmbre;
    this.sal_nmbre = this.$route.params.sal_nmbre;
    this.searchItems(this.$route.params.query);
  },
  mounted: function() {

  },
  methods: {
    fetchTpocor()
    {
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpocors.length; j++){
            if (this.items[i].tcr_id == this.tpocors[j]._id){
              this.items[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
              j = this.tpocors.length;
            }
          }
        }

      });
    },
    fetchEstante(){
      let uri = '/estantes/sala';
      this.axios.post(uri, { cmp_id: this.$store.state.company, sal_id: this.$route.params.query.sal_id }).then((response) => {
        this.estantes = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.estantes.length; j++){
            if (this.items[i].caja_docs[0].est_id == this.estantes[j]._id){
              this.items[i].est_cdgo = this.estantes[j].est_cdgo;
              j = this.estantes.length;
            }
          }
        }

      });
    },
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.dependencias.length; j++){
            if (this.items[i].dep_id == this.dependencias[j]._id){
              this.items[i].dep_cdgo = this.dependencias[j].dep_cdgo;
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.series.length; j++){
            if (this.items[i].ser_id == this.series[j]._id){
              this.items[i].ser_cdgo = this.series[j].ser_cdgo;
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }

      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/transferencias';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.nflios = 0;
          for (let i = 0; i < list.length; i++){
            for (let j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_cdgo = this.dependencias[j].dep_cdgo;
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (let j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_cdgo = this.series[j].ser_cdgo;
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                j = this.series.length;
              }
            }

            for (let j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].documento = this.tpocors[j].tcr_cdgo + '-' + list[i].dcm_ano + '-' + list[i].dcm_cdgo;
                j = this.tpocors.length;
              }
            }

            for (let j = 0; j < this.estantes.length; j++){
              if (list[i].caja_docs.length > 0){
                if (list[i].caja_docs[0].est_id == this.estantes[j]._id){
                  list[i].est_cdgo = this.estantes[j].est_cdgo;
                  j = this.estantes.length;
                }
              }
            }

            list[i].dcm_adjuntos = 'No';
            if (list[i].adjuntos)
              if (list[i].adjuntos.length > 0)
                list[i].dcm_adjuntos = 'Si';
          
            if (list[i].dcm_nflios)
              this.nflios += list[i].dcm_nflios;
          }

          this.items = list;

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteTransferencias';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.est_cdgo = this.items[i].est_cdgo;
        item.caj_cdgo = this.items[i].caj_cdgo;
        item.lgj_nmro = this.items[i].lgj_nmro;
        item.documento = this.items[i].documento;
        item.dep_cdgo = this.items[i].dep_cdgo;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_cdgo = this.items[i].ser_cdgo;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_fchaini = moment.utc(this.items[i].dcm_fchaini).format('DD/MMM/YYYY');
        if (this.items[i].dcm_fchafin != null){
          item.dcm_fchafin = moment.utc(this.items[i].dcm_fchafin).format('DD/MMM/YYYY');
        } else {
          item.dcm_fchafin = "";
        }
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dcm_tomo = this.items[i].dcm_tomo;
        item.dcm_adjuntos = this.items[i].dcm_adjuntos;
        item.dcm_nflios = this.items[i].dcm_nflios;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de inventario de documentos"],
      	["Tipo de archivo: " + this.tpa_nmbre],
        ["Sala: " + this.sal_nmbre],
        [],
        ["Estante", "Caja", "Inventario", "Documento", "Cód. Dependencia", "Dependencia", "Cód. Serie", "Serie", "Fecha inicio", "Fecha fin", "Nombre del expediente", "Tomo", "Archivos adjuntos", "# Folios"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A9" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
